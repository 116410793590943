import { InputOnChangeData, SearchBox, SearchBoxChangeEvent } from "@fluentui/react-components";
import { useContext } from "react";
import { TabContext } from "sub/context/TabContext";
import { refreshTicker } from "sub/library/epCommon";

export function ListPollsSearch() {

    const tabContext = useContext(TabContext);

    let debounceTimer: NodeJS.Timeout;

    function _searchChange(data: InputOnChangeData) {
        clearTimeout(debounceTimer);
        
        // Wait for the user to stop typing before searching
        debounceTimer = setTimeout(() => {
            tabContext.tableSearch.current = data.value;
            tabContext.setRefreshTickerData(refreshTicker);
        }, 300);
    }

    return (
        <SearchBox placeholder="Search..." style={{ width: "15em" }} onChange={(event: SearchBoxChangeEvent, data: InputOnChangeData) => _searchChange(data)} />
    );


}