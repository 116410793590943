import { Theme, teamsDarkTheme, teamsHighContrastTheme, teamsLightTheme } from "@fluentui/react-components";
import { TabContextClass, TabDialogType } from "sub/context/TabContext";
import { authentication, app as microsoftTeams } from "@microsoft/teams-js";
import { hyDebug, hyDebugLocal } from "./hyDebug";
import { AppContextClass } from "sub/context/AppContext";
import { backendGetPollOrResultClient } from "./epBackend";
import { EnumPolLView, PollDataClass, duplicatePoll } from "sub/bot-client-shared/PollDataClass";
import { EnumPollWorkflow } from "sub/context/PollContext";

export const environment = (window.location.hostname === "localhost" ? "local" : "azure");

export const app_name = "easypoll-client";
export const app_version = "2024-11-14b";

export const link_teams_app = "https://teams.microsoft.com/l/entity/312ac5ed-982b-453a-a784-f5258c182593";
export const link_teams_app_template = "https://teams.microsoft.com/l/entity/312ac5ed-982b-453a-a784-f5258c182593/index?&context=%7B%22subEntityId%22:%20%22template%22%7D";
export const link_purchase = "https://www.easy-poll.app/buy";

export function getBaseStyle(themeString: string): string {
  return (themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast");
}

export function getTheme(themeString: string): Theme {
  if (themeString === "dark") { return teamsDarkTheme; }
  if (themeString === "contrast") { return teamsHighContrastTheme; }

  return teamsLightTheme;
}


export function hyDate(paramDate: string | Date): Date {
  try {
    // If paramDate is not a string, than it is already a date object.
    if (typeof paramDate !== "string") { return new Date(paramDate); }

    // Split the date string into its components
    const parts = paramDate.split('-').map(part => parseInt(part, 10));

    // Create a new Date object using the local timezone
    // Note: The month argument is 0-indexed, so subtract 1 to get the correct month
    return new Date(parts[0], parts[1] - 1, parts[2]);
  } catch (Ex) {
    hyDate("Error: Date object could not be created for '" + paramDate + "'");
    return new Date();
  }
}

export function transformDateToDayMonthYear(locale: string, date: Date): string {
  return date.toLocaleDateString((locale) ? locale : 'default', { day: '2-digit', month: '2-digit', year: 'numeric' });
}

export const refreshTicker = (prevValue: number) => {
  if (prevValue >= 10) {
    return 1; // reset to 1 if value has reached 10
  }
  return prevValue + 1;
};

export function dialog_close_refresh(tabContext: TabContextClass, appContext?: AppContextClass | undefined) {
  if (appContext) {
    // If appContext is set, update auth data as well.
    appContext.setAuthRefreshTicker(refreshTicker);
  }
  tabContext.setRefreshTickerData(refreshTicker);

  tabContext.setTabDialog(TabDialogType.None);
}

export async function getSSOToken(isSSOEnabled: boolean, setIsSSOEnabled: (val: boolean) => void, askSilent: boolean = true): Promise<string> {
  try {
    if (microsoftTeams.isInitialized() === false) { await microsoftTeams.initialize(); }

    let token = "";
    if (askSilent) {
      token = await authentication.getAuthToken({ claims: [], silent: askSilent });
    } else {
      token = await authentication.getAuthToken();
    }

    if (isSSOEnabled && !token) {
      // SSO is enabled but no token was returned. Disable SSO.
      setIsSSOEnabled(false);
    }

    if (!isSSOEnabled && token) {
      // SSO is not enabled but a token was returned. Enable SSO.
      setIsSSOEnabled(true);
    }

    return token;
  } catch (ex) {
    hyDebug("Error getting sso token: " + ex);

    if (isSSOEnabled) {
      // SSO is enabled but no token was returned. Disable SSO.
      setIsSSOEnabled(false);
    }

    return "";
  }
}

export function GenerateGUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

export function getNullSubstring(input: string | null | undefined, start: number, length: number): string {
  try {
    if (input === null || input === undefined) {
      return "";
    }

    if (start >= input.length) {
      return "";
    }

    return input.substring(start, length);

  } catch (ex: any) {

    hyDebug("getNullSubstring: " + ex?.message);
    return "";
  }
}


export async function proceedFromDuplicateToNewPollEntry(target_is_template: boolean, tabContext: TabContextClass, appContext: AppContextClass, setPollWorkflow: (param: EnumPollWorkflow) => void, setPollData: (param: PollDataClass) => void) {
  try {
    hyDebugLocal("Fetch Data for Duplicate Poll");

    if (tabContext.activePollElement.current.poll_guid && tabContext.activePollElement.current.poll_secret) {

      // Get full poll data including answer options.
      const pollResults = await backendGetPollOrResultClient(EnumPolLView.poll, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.displayName, appContext.appAuthData.lang, tabContext.activePollElement.current.poll_guid, tabContext.activePollElement.current.poll_secret);

      if (pollResults.success) {
        // Duplicate poll data and set it as new poll data.
        setPollData(duplicatePoll(target_is_template, pollResults.pollData, appContext.appAuthData.hasActiveLicense));
      }
    }
  } catch (ex) {
    hyDebug("Duplicate Poll failed: " + ex);
  }

  const action = (target_is_template ? EnumPollWorkflow.newTemplate_entry : EnumPollWorkflow.newPoll_entry);
  setPollWorkflow(action);

}