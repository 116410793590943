import { Dialog, Button, DialogSurface, DialogTitle, DialogContent, Text, DialogActions, Image, DialogBody, Spinner, Switch, MessageBar, MessageBarBody } from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { TabContext, TabDialogType, TabTableMode } from "sub/context/TabContext";
import { Dismiss24Regular, CartRegular } from "@fluentui/react-icons";
import { backendEditPollClient, backendGetPollOrResultClient, backendTrackEvent } from "sub/library/epBackend";
import { AppContext } from "sub/context/AppContext";
import { EnumPolLView, PollDataClass } from "sub/bot-client-shared/PollDataClass";
import { Warning, checkEditPollResponse, cleanCardFromActions, createCardFromPollData } from "sub/bot-client-shared/adaptiveCards/botCardBuilder";
import { AdaptiveCardReact } from "sub/blocks/AdaptiveCardReact";
import { dialog_close_refresh, link_purchase } from "sub/library/epCommon";
import { hyDebug } from "sub/library/hyDebug";

export function TabDialogViewPoll() {
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    const [isLoading, setIsLoading] = useState(true);

    const [isRestricted, setIsRestricted] = useState(false);
    const [warningText, setWarningText] = useState("");
    const [cardPayload, setCardPayload] = useState<any>(null);

    const [showResultDetails, setShowResultDetails] = useState(appContext.viewSettings.session.viewResultWithDetails);

    useEffect(() => {

        backendTrackEvent(appContext.appAuthData.lang, "Tab", "Dialog.ViewPoll", "Details: " + showResultDetails, appContext);

        const fetchData = async () => {
            try {
                const pollData = new PollDataClass();

                pollData.poll_guid = tabContext.activePollElement.current.poll_guid;
                pollData.poll_secret = tabContext.activePollElement.current.poll_secret;

                const requested_view = (tabContext.tabDialog === TabDialogType.ViewOrPostPoll ? (showResultDetails ? EnumPolLView.result_details : EnumPolLView.result) : EnumPolLView.poll);
                const getPollResult = await backendGetPollOrResultClient(requested_view, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.displayName, appContext.appAuthData.lang, pollData.poll_guid, pollData.poll_secret);

                if (getPollResult.success) {
                    const cardPayload = cleanCardFromActions(createCardFromPollData(null, getPollResult.pollData, false, appContext.appAuthData.locale), true);
                    setCardPayload(cardPayload);
                    setIsRestricted(getPollResult.pollData.poll_results_restricted);

                }

                setWarningText(""); // Clear warning text

            } catch (ex: any) {
                hyDebug("Error: " + ex?.message);
                setWarningText("An unknown error occured. Please contact support.");
            }

            setIsLoading(false);

        };

        fetchData();
    }, [showResultDetails]);

    return (
        <Dialog defaultOpen onOpenChange={(event, data) => { if (!data.open) { dialog_close_refresh(tabContext); } }}>
            <DialogSurface>
                <DialogBody style={{ minHeight: "20em" }}>
                    <DialogTitle action={<Button appearance="subtle" aria-label="close" onClick={() => { dialog_close_refresh(tabContext); }} icon={<Dismiss24Regular />} />}>{(tabContext.tabDialog === TabDialogType.ViewOrPostPoll ? "View poll results" : "View template")}</DialogTitle>
                    <DialogContent>

                        <Flex column fillFlex gap="small" padding="medium" alignItems="center" justifyContent="center" style={{ overflow: 'auto' }}>
                            {(tabContext.tabDialog === TabDialogType.ViewOrPostPoll) && (
                                <FlexItem push >
                                    <Switch
                                        checked={showResultDetails}
                                        label={"Show details"}
                                        labelPosition="before"
                                        onChange={() => { _toogleShowDetails(); }}
                                    />
                                </FlexItem>
                            )}
                            {isLoading && (<Spinner />)}

                            {!isLoading && (
                                <div style={{ maxWidth: '80%', height: "80%" }}>
                                    <AdaptiveCardReact cardPayload={cardPayload} />
                                </div>
                            )}

                            {!isLoading && warningText && (
                                <FlexItem column>
                                    <MessageBar intent="warning">
                                        <MessageBarBody>{warningText}</MessageBarBody>
                                    </MessageBar>
                                </FlexItem>
                            )}

                        </Flex>

                    </DialogContent>
                    <DialogActions>
                        {tabContext.tabDialog === TabDialogType.ViewOrPostPoll && (<>
                            {isRestricted && (<Button appearance="secondary" onClick={() => { _unlockPoll(); }}>Reveal</Button>)}
                            {!isRestricted && (<Button appearance="secondary" onClick={() => { dialog_close_refresh(tabContext); }}>Close</Button>)}
                            {isRestricted && (<Button disabled={isLoading} appearance="primary" onClick={() => { _purchase_button(); }} icon={<CartRegular />}>Purchase license</Button>)}
                        </>)}

                        {tabContext.tabDialog === TabDialogType.ViewTemplate && (
                            <Button appearance="secondary" onClick={() => { dialog_close_refresh(tabContext); }}>Close</Button>
                        )}
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );

    async function _unlockPoll() {
        try {
            const pollData = new PollDataClass();

            pollData.poll_guid = tabContext.activePollElement.current.poll_guid;
            pollData.poll_secret = tabContext.activePollElement.current.poll_secret;

            const getPollResult = await backendEditPollClient("unlock_results", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.lang, pollData.poll_guid, pollData.poll_secret);

            checkEditPollResponse(getPollResult);

            const cardPayload = cleanCardFromActions(createCardFromPollData(null, getPollResult.pollData, false, appContext.appAuthData.locale), true);
            setCardPayload(cardPayload);

            setIsRestricted(getPollResult.pollData.poll_results_restricted);
            setWarningText(""); // Clear warning text

        } catch (ex: any) {
            if (ex instanceof Warning) {
                setWarningText(ex.message);
            } else {
                hyDebug("Error: " + ex?.message);
                setWarningText("An unknown error occured. Please contact support.");
            }
        }

        setIsLoading(false);
    }

    function _toogleShowDetails() {
        setShowResultDetails(!showResultDetails);

        const newVal = !showResultDetails;

        appContext.viewSettings.session.viewResultWithDetails = newVal;
        appContext.updateViewSettings({ session: appContext.viewSettings.session })
    }

    function _purchase_button() {
        backendTrackEvent(appContext.appAuthData.lang, "Tab", "Upgrade.Purchase", "", appContext);
        window.open(link_purchase, '_blank');
    }


}