import { tokens } from '@fluentui/react-components';
import { Action, AdaptiveCard } from 'adaptivecards';
import { useEffect, useRef } from 'react';
import { hyDebugLocal } from 'sub/library/hyDebug';


export function AdaptiveCardReact(props: { cardPayload: any, funcOnExecuteAction?: (action: Action) => void }) {
    const cardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        // If card payload is not set, return
        if (!props.cardPayload) {
            return;
        }

        hyDebugLocal("Adaptive Card Rendered.");

        const card = new AdaptiveCard();
        card.parse(props.cardPayload);

        if (props.funcOnExecuteAction) {
            // Set card function to execute action
            card.onExecuteAction = props.funcOnExecuteAction;
        }
        const renderedCard = card.render();


        if (cardRef.current) {
            // Remove the old card (if set)
            while (cardRef.current.firstChild) {
                cardRef.current.removeChild(cardRef.current.firstChild);
            }

            // Add card to the DOM
            if (renderedCard) {
                cardRef.current.appendChild(renderedCard);
            }
        }
    }, [props.cardPayload]);

    return <div ref={cardRef} style={{ boxShadow: tokens.shadow2 }} />;
}