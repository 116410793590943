import { InputOnChangeData, SearchBox, Text, Tab, TabList, SelectTabData } from "@fluentui/react-components";
import { useContext } from "react";
import { AppContext, AppContextClass, EnumAppEnvironment } from "sub/context/AppContext";
import { TabContext, TabContextClass, TabTableMode } from "sub/context/TabContext";
import { refreshTicker } from "sub/library/epCommon";

export function ListPollsModeSelector() {

    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    return (
        <TabList size="small" selectedValue={tabContext.tableMode} onTabSelect={(event, data: SelectTabData) => { switchTableMode(appContext, tabContext, data.value as TabTableMode); }}>
            <Tab value={TabTableMode.modePolls}><Text size={500}>Polls</Text></Tab>
            <Tab value={TabTableMode.modeTemplates}><Text size={500}>Templates</Text></Tab>
        </TabList>
    );

}

function switchTableMode(appContext: AppContextClass, tabContext: TabContextClass, mode: TabTableMode) {
    tabContext.setTableMode(mode);
    tabContext.listPollResults.current.polls = [];
    tabContext.setRefreshTickerData(refreshTicker);
}
