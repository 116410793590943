import { mergeClasses } from "@fluentui/react-components";
import { AppContext } from "sub/context/AppContext";
import { useContext } from "react";
import { getBaseStyle } from "sub/library/epCommon";


export function MainContainer(props: { botDialog?: boolean, children: React.ReactNode }) {
    const appContext = useContext(AppContext);

    return (
        <div className={mergeClasses(getBaseStyle(appContext.themeString), (props?.botDialog ? "main-bot-dialog" : "main-container"))}>
            {props.children}
        </div>
    );
}