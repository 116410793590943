// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import EntryChat from "sub/EntryChat";
import { AppContext, AppContextClass, EnumAppEnvironment } from "sub/context/AppContext";
import { getSSOToken, getTheme, refreshTicker } from "sub/library/epCommon";
import { Flex } from "sub/blocks/FlexBoxes";
import EntryTab from "sub/EntryTab";
import { useAppAuthorization } from "sub/library/useAppAuthorization";
import NoAccessPage from "sub/NoAccessPage";
import { useTeams } from "@microsoft/teamsfx-react";
import { hyDebugLocal } from "sub/library/hyDebug";
import { ViewSettings } from "sub/library/epViewSettings";
import { useEffect, useRef, useState } from "react";

// Refresh time
const refreshTimeAuth = 60 * 60 * 1000; // Every 1 hour

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {

  const [{ loading, themeString, inTeams }] = useTeams();
  const [isSSOEnabled, setIsSSOEnabled] = useState(false);
  const [authRefreshTicker, setAuthRefreshTicker] = useState(1);
  const appDeepLink = useRef<string>("");

  hyDebugLocal("ThemeString: " + themeString + " | inTeams: " + inTeams);

  const appAuthData = useAppAuthorization(loading, inTeams, authRefreshTicker, isSSOEnabled, setIsSSOEnabled, appDeepLink).data;

  // Initialize timer for auth refresh (every 60 min)
  useEffect(() => {
    const interval = setInterval(() => {
      setAuthRefreshTicker(refreshTicker)
    }, refreshTimeAuth);

    return () => clearInterval(interval);
  }, [authRefreshTicker]);

  // Create settings objects
  const appEnvironment = useRef(EnumAppEnvironment.not_set);

  const [viewSettings, setViewSettings] = useState(ViewSettings.CreateFromStorage());
  const updateViewSettings = (updates: Partial<ViewSettings>) => {
    setViewSettings(current => ({ ...current, ...updates }));
  };

  const appContext: AppContextClass = {
    environment: appEnvironment,
    appAuthData: appAuthData,
    appDeepLink : appDeepLink,
    setAuthRefreshTicker: setAuthRefreshTicker,
    isSSOEnabled: isSSOEnabled,
    setIsSSOEnabled: setIsSSOEnabled,
    themeString: themeString,

    viewSettings: viewSettings,
    updateViewSettings: updateViewSettings,
  };

  return (
    <AppContext.Provider value={appContext}>
      <FluentProvider theme={getTheme(themeString)} style={{ background: tokens.colorNeutralBackground3 }}>
        <Router>
          {appAuthData.loading ? (
            <Flex fillFlex alignItems="center" justifyContent="center">
              <Spinner style={{ margin: 100 }} />
            </Flex>
          ) : (
            appAuthData.accessGranted ? (
              <Routes>
                <Route path="/createPoll" element={<EntryChat />} />
                <Route path="/tab" element={<EntryTab />} />

                <Route path="*" element={<Navigate to={"/tab"} />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="*" element={<NoAccessPage />} />
              </Routes>
            )
          )}
        </Router>
      </FluentProvider>
    </AppContext.Provider>
  );
}

