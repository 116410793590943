import { Dialog, Button, DialogSurface, DialogTitle, DialogContent, DialogActions,  DialogBody, Spinner, MessageBar, MessageBarBody } from "@fluentui/react-components";
import { useContext, useEffect, useState } from "react";
import { Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { TabContext, TabDialogType } from "sub/context/TabContext";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { backendCreateEditVoteClient, backendGetPollOrResultClient, backendTrackEvent } from "sub/library/epBackend";
import { AppContext } from "sub/context/AppContext";
import { EnumPolLView, PollDataClass } from "sub/bot-client-shared/PollDataClass";
import { Warning, checkEditVoteResponse, cleanCardFromActions, createCardFromPollData } from "sub/bot-client-shared/adaptiveCards/botCardBuilder";
import { AdaptiveCardReact } from "sub/blocks/AdaptiveCardReact";
import { dialog_close_refresh } from "sub/library/epCommon";
import { hyDebug, hyDebugLocal } from "sub/library/hyDebug";
import { BotAppActionDataCreateVote } from "sub/bot-client-shared/BotAppActionDataCreateVote";

export function TabDialogEditVote() {
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    const [isLoading, setIsLoading] = useState(true);
    const [cardPayload, setCardPayload] = useState<any>(null);

    const [warningText, setWarningText] = useState("");

    useEffect(() => {
        backendTrackEvent(appContext.appAuthData.lang, "Tab", "Dialog.EditVote", "", appContext);
        
        const fetchData = async () => {
            const pollData = new PollDataClass();

            pollData.poll_guid = tabContext.activePollElement.current.poll_guid;
            pollData.poll_secret = tabContext.activePollElement.current.poll_secret;

            const view = EnumPolLView.poll;
            const getPollResult = await backendGetPollOrResultClient(view, appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.displayName, appContext.appAuthData.lang, pollData.poll_guid, pollData.poll_secret);

            if (getPollResult.success) {
                const cardPayload = cleanCardFromActions(createCardFromPollData(null, getPollResult.pollData, false, appContext.appAuthData.locale), false);

                setCardPayload(cardPayload);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const onExecuteAction = (action: any) => {
        hyDebugLocal("Action executed: " + action?.id);
        if (action?.id === "pollSubmitVote") {

            const fetchData = async () => {

                try {
                    const botActionDataCreateVote = new BotAppActionDataCreateVote(action?.data);
                    const voteResult = await backendCreateEditVoteClient("new", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.lang, botActionDataCreateVote.pollGuid, botActionDataCreateVote.pollSecret, botActionDataCreateVote.answerGuids, false);

                    checkEditVoteResponse(voteResult, action?.id);

                    // Switch to view poll dialog.
                    tabContext.setTabDialog(TabDialogType.ViewOrPostPoll);
                    setWarningText(""); // Clear warning text

                } catch (ex: any) {
                    if (ex instanceof Warning) {
                        setWarningText(ex.message);
                    } else {
                        hyDebug("Error: " + ex?.message);
                        setWarningText("An unknown error occured. Please contact support.");
                    }
                }

                setIsLoading(false);
            };

            fetchData();


        }
        hyDebugLocal("Data: " + action?.data); // Access the action data here
    };

    return (
        <Dialog defaultOpen onOpenChange={(event, data) => { if (!data.open) { dialog_close_refresh(tabContext); } }}>
            <DialogSurface>
                <DialogBody style={{ minHeight: "20em" }}>
                    <DialogTitle action={<Button appearance="subtle" aria-label="close" onClick={() => { dialog_close_refresh(tabContext); }} icon={<Dismiss24Regular />} />}>Edit vote</DialogTitle>
                    <DialogContent>
                        <Flex column fillFlex gap="small" padding="medium" alignItems="center" justifyContent="center" style={{ overflow: 'auto' }}>
                            {isLoading && (<Spinner />)}

                            {!isLoading && (
                                <div style={{ maxWidth: '80%', height: "80%" }}>
                                    <AdaptiveCardReact cardPayload={cardPayload} funcOnExecuteAction={onExecuteAction} />
                                </div>
                            )}

                            {!isLoading && warningText && (
                                <FlexItem column>
                                    <MessageBar intent="warning">
                                        <MessageBarBody>{warningText}</MessageBarBody>
                                    </MessageBar>
                                </FlexItem>
                            )}

                        </Flex>

                    </DialogContent>
                    <DialogActions>
                        <Button appearance="secondary" onClick={() => { dialog_close_refresh(tabContext); }}>Close</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );


}