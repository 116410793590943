import React from 'react';
import { Text, tokens } from "@fluentui/react-components";
import {  DismissRegular } from "@fluentui/react-icons";


interface AlertProps {
    wrap?: boolean;
    intent?: 'warning' | 'error' | 'success'; // Updated type for intent
    visible?: boolean;
    children: React.ReactNode; // Added children prop
}

const Alert: React.FC<AlertProps> = ({ wrap = false, intent = 'warning', visible = true, children }) => {
    if (!visible) {
        return null;
    }

    let alertStyle: React.CSSProperties = {};

    // Set border color and background color based on intent value
    switch (intent) {
        case 'warning':
            alertStyle = { border: `${tokens.strokeWidthThin} solid ${tokens.colorPaletteYellowBorder2}`, background: tokens.colorPaletteYellowBackground2 };
            break;
        case 'error':
            alertStyle = { border: `${tokens.strokeWidthThin} solid ${tokens.colorPaletteRedBorder2}`, background: tokens.colorPaletteRedBackground2 };
            break;
        case 'success':
            alertStyle = { border: `${tokens.strokeWidthThin} solid ${tokens.colorPaletteGreenBorder2}`, background: tokens.colorPaletteGreenBackground2 };
            break;
        default:
            break;
    }

    return (
        <Text wrap={wrap} style={{
            ...alertStyle,
            borderRadius: tokens.borderRadiusMedium,
            paddingLeft: tokens.spacingHorizontalSNudge,
            paddingRight: tokens.spacingHorizontalSNudge,
            paddingTop: tokens.spacingVerticalXXS,
            paddingBottom: tokens.spacingVerticalXXS
        }}>
            {children}
        </Text>
    );
};

export default Alert;