import * as CryptoJS from 'crypto-js';
import Axios from "axios";
import { GenerateGUID, app_name, app_version, environment } from "./epCommon";
import { CreateEditVoteResults, CreatePollResults, EditPollResults, GetPollOrResultResults, GetUserHashResults, ListPollResults, UpdateLoginResults, UserManageLicenseResults } from './epBackendTypes';
import { EnumPolLView, PollDataClass } from 'sub/bot-client-shared/PollDataClass';

import client_secrets from './client_secrets.json'
import { hyDebug, hyDebugLocal } from './hyDebug';
import { AppContextClass } from 'sub/context/AppContext';
import { SettingsStorage } from './epViewSettings';
import { TabTableMode } from 'sub/context/TabContext';


// As the bot is running inside devtunnel.ms, also the backend must run inside devtunnel.ms.
// You can find the devtunnel.ms address for backend in "Output" window -> show output from "DevTunnel" when the backend is running.
const backendLocalEndpointTunnel = "https://3h5jj83b-7099.euw.devtunnels.ms/";

export function GetCurrentOffset(): number {
    // getTimeZoneOffset has reversed signs
    const offset = (new Date().getTimezoneOffset()) * (-1.0);
    return (offset / 60.0);
}

export function GetCurrentTimezone(): string {
    if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
        return Intl.DateTimeFormat().resolvedOptions()?.timeZone;
    } else {
        return "";
    }
}

export function GetCurrentOS(): string {
    const start = navigator.userAgent.indexOf('(');
    const end = navigator.userAgent.indexOf(')');

    if (start >= 1 && end >= 2) {
        return navigator.userAgent.substring(start + 1, end);
    }

    return "Unknown OS";
}

export function GetOrCreateAnonymGUID(appContext: AppContextClass): string {
    try {
        let guid = appContext.viewSettings.storage.anonym_guid;

        if (!guid) {
            // if GUID not exist, generate one.
            guid = "ep:" + GenerateGUID();
            appContext.viewSettings.storage.anonym_guid = guid;
            appContext.updateViewSettings({ storage: appContext.viewSettings.storage })
            SettingsStorage.WriteToStorage(appContext.viewSettings.storage);
            hyDebug("Anonym GUID created: " + guid);
        }

        return guid;
    } catch (ex: any) {
        hyDebug("Anonym GUID not created. Error: " + ex.message);
        return "";
    }
}


export function getUserHashClient(user_upn: string, user_id: string, tenant_id: string, displayName: string): string {

    const dateTime = new Date().toISOString();
    var message = app_name + "##," + client_secrets.client_hash_secret + "##," + user_upn + "##," + user_id + "##," + tenant_id + "##," + dateTime + "##," + displayName;

    const myKey = "6rM78^y81PLU61ucC^|h0|v?1>AVIOAC"; // 32 bytes key for AES-256 (Ascii codec)
    const myIV = "<dducxjy>>;ufcG0"; // 16 bytes key for AES (Ascii codec)

    const key = CryptoJS.enc.Utf8.parse(myKey);

    const iv = CryptoJS.enc.Utf8.parse(myIV);

    const cipherData = CryptoJS.AES.encrypt(message, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return cipherData.toString(); // cryptoJS is returning the cipher data always in base64 encoding (be aware!)
}

async function backend(backend_func: string, key: string, payload: any, attempt: number = 1): Promise<any> {

    const backend_func_key = backend_func + key;

    let endpoint = "";
    if (environment === "local") {
        endpoint = backendLocalEndpointTunnel;
    } else {
        endpoint = "https://easypollbackend.azurewebsites.net/";
    }

    const url = endpoint + backend_func_key;

    try {
        const response = await Axios.post(url, payload);
        return response.data;

    } catch (err: any) {

        if (attempt < 3 && err?.response?.status !== 250 && err?.response?.status !== 450) {
            // Retry 3 times if no custom error was returned.
            await new Promise(resolve => setTimeout(resolve, 500 * attempt));
            return await backend(backend_func, key, payload, attempt + 1);
        }

        let funcErrorMsg = "";
        if (err?.response?.status === 404) {
            funcErrorMsg = `There may be a problem with the deployment of Azure Function App.`;
        } else if (err.message === "Network Error") {
            funcErrorMsg =
                "Cannot call Azure Function due to network error, please check your network connection status.";
        } else {
            funcErrorMsg = err.message;
            if (err.response?.data?.error) {
                funcErrorMsg += ": " + err.response.data.error;
            }
        }

        throw new Error(funcErrorMsg);
    }
}

export async function backendGetUserToken(paramUserUPN: string, paramUserID: string, paramHash: string): Promise<GetUserHashResults> {
    const backend_func = "api/GetUserToken";
    const key = "?code=p6HYl53hnO9rs2fStgrW8hlsJyr1FrruBpkOE3vYYtN7AzFuYX2wKA=="

    const payload = {
        user_upn: paramUserUPN,
        user_id: paramUserID,
        user_hash: paramHash
    };

    try {

        const response: any = await backend(backend_func, key, payload);
        return new GetUserHashResults(response);
    } catch (err) {
        throw err;
    }
}

export async function backendUpdateLogin(paramUserUpn: string, paramUserId: string, paramToken: string, paramSSOToken: string | null, paramLang: string, paramLocale : string, paramHost: string): Promise<UpdateLoginResults> {
    const backend_func = "api/UpdateLogin";
    const key = "?code=P2l56wnE7NjDrGAieF4xJILRxvZ6dqK3pGPcMTCwpqlAAzFuQ1yUTg=="

    const payload = {
        user_upn: paramUserUpn,
        user_id: paramUserId,
        user_token: paramToken,
        sso_token: paramSSOToken ? paramSSOToken : null,
        app_name: app_name,
        app_version: app_version,

        language: paramLang,
        locale : paramLocale,

        utc_offset: GetCurrentOffset(),
        timezone: GetCurrentTimezone(),
        os_version: GetCurrentOS(),
        host_details: paramHost
    };

    try {

        const response: any = await backend(backend_func, key, payload);
        return new UpdateLoginResults(response);
    } catch (err) {
        throw err;
    }
}

export async function backendCreatePollClient(paramUserUpn: string, paramUserId: string, paramToken: string, paramLang: string, paramDisplayName: string, paramPollData: PollDataClass, paramUserDetailsFastlane: boolean): Promise<CreatePollResults> {
    const backend_func = "api/CreatePoll";
    const key = "?code=FwvI8kqrrzFNqlAMfdGNRKj_fBxdHPbrLGVhuebUtAW9AzFu0d6axg=="

    const payload = {
        user_upn: paramUserUpn,
        user_id: paramUserId,
        user_token: paramToken,

        app_name: app_name,
        app_version: app_version,

        utc_offset: GetCurrentOffset(),
        timezone: GetCurrentTimezone(),

        language: paramLang,
        displayName: paramDisplayName ?? "",

        pollData: paramPollData,
        create_env: "tab"
    };

    try {

        const response: any = await backend(backend_func, key, payload);
        return new CreatePollResults(response);
    } catch (err) {
        throw err;
    }
}


export async function backendListPolls(paramUserUpn: string, paramUserId: string, paramToken: string, paramSSOToken: string | null, paramLang: string, paramEnvironment: string, paramSearch: string, paramMode: TabTableMode): Promise<ListPollResults> {
    const backend_func = "api/ListPolls";
    const key = "?code=J6L6aZzNFzOzKdr8SmhDAhLhAwYarypu3lllgMsnvXm5AzFugYD1xQ%3D%3D";

    const payload = {
        user_upn: paramUserUpn,
        user_id: paramUserId,
        user_token: paramToken,
        sso_token: paramSSOToken ? paramSSOToken : null,
        language: paramLang,

        app_name: app_name,
        app_version: app_version,

        utc_offset: GetCurrentOffset(),
        timezone: GetCurrentTimezone(),

        environment: paramEnvironment,
        mode : paramMode.toString(),
        search: paramSearch
    };

    try {
        const response: any = await backend(backend_func, key, payload);
        return new ListPollResults(response);
    } catch (err) {
        throw err;
    }
}

export async function backendEditPollClient(paramAction: string, paramUserUpn: string, paramUserId: string, paramUserToken: string, paramLang: string, paramPollGuid: string, paramPollSecret: string): Promise<EditPollResults> {
    const backend_func = "api/EditPoll";
    const key = "?code=G-v9ZL49pPHPiGJ-ephlF--JpXDJHHHeR4pBbV7YghA-AzFu4iLJtw=="

    const payload = {
        action: paramAction,
        user_upn: paramUserUpn,
        user_id: paramUserId,
        user_token: paramUserToken,

        app_name: app_name,
        app_version: app_version,

        utc_offset: GetCurrentOffset(),
        timezone: GetCurrentTimezone(),

        language: paramLang,

        poll_guid: paramPollGuid,
        poll_secret: paramPollSecret,
    };

    try {

        const response: any = await backend(backend_func, key, payload);
        return new EditPollResults(response);
    } catch (err) {
        throw err;
    }

}

export async function backendCreateEditVoteClient(paramAction: string, paramUserUpn: string, paramUserId: string, paramUserToken: string, paramLang: string, paramPollGuid: string, paramPollSecret: string, paramAnswerGuids: string[], paramUserDetailsFastlane: boolean): Promise<CreateEditVoteResults> {
    const backend_func = "api/CreateEditVote";
    const key = "?code=hxoLlfZPLM7tnvK2aneU4ZsEiN-S9eMUWToJ-IvcbngjAzFu9gNsJw=="

    const payload = {
        action: paramAction,
        user_upn: paramUserUpn,
        user_id: paramUserId,
        user_token: paramUserToken, // Client only

        app_name: app_name,
        app_version: app_version,

        utc_offset: GetCurrentOffset(),
        timezone: GetCurrentTimezone(),

        language: paramLang,

        poll_guid: paramPollGuid,
        poll_secret: paramPollSecret,
        answer_guids: paramAnswerGuids,

        info_fastlane: paramUserDetailsFastlane // Bot only
    };

    try {

        const response: any = await backend(backend_func, key, payload);
        return new CreateEditVoteResults(response);
    } catch (err) {
        throw err;
    }
}

export async function backendGetPollOrResultClient(paramRequestedPollView: EnumPolLView, paramUserUpn: string, paramUserId: string, paramToken: string, paramDisplayName: string, paramLang: string, paramPollGuid: string, paramPollSecret: string): Promise<GetPollOrResultResults> {
    const backend_func = "api/GetPollOrResult";
    const key = "?code=ci3Ombmn81BOvuDLdfMcm81CtTKiz0QgbCVpySWEbRONAzFuah6HJA=="

    const payload = {
        requested_poll_view: paramRequestedPollView,
        user_upn: paramUserUpn,
        user_id: paramUserId,
        user_token: paramToken, // Client only

        app_name: app_name,
        app_version: app_version,

        utc_offset: GetCurrentOffset(), // Client only
        timezone: GetCurrentTimezone(), // Client only

        language: paramLang,
        displayName: paramDisplayName ?? "",

        poll_guid: paramPollGuid,
        poll_secret: paramPollSecret,
    };

    try {

        const response: any = await backend(backend_func, key, payload);
        return new GetPollOrResultResults(response);
    } catch (err) {
        throw err;
    }
}

export async function backendUserManageLicense(paramAction: string, paramUserUpn: string, paramUserId: string, paramToken: string, paramLang: string, paramKey: string): Promise<UserManageLicenseResults> {
    const backend_func = "api/UserManageLicense";
    const key = "?code=gF3L6jOGqw8t2A-OH6rUR4kFRKb2qBPCVW9IaC8OQ3FmAzFuHtU0xA=="

    const payload = {
        user_upn: paramUserUpn,
        user_id: paramUserId,
        user_token: paramToken, // Client only

        app_name: app_name,
        app_version: app_version,

        action: paramAction,
        license_key: paramKey,

        utc_offset: GetCurrentOffset(), // Client only
        timezone: GetCurrentTimezone(), // Client only
        language: paramLang
    };

    try {

        const response: any = await backend(backend_func, key, payload);
        return new UserManageLicenseResults(response);
    } catch (err) {
        throw err;
    }
}

export function backendTrackEvent(paramLang: string, paramEventCategory: string, paramEventName: string, paramEventDetails: string, appContext: AppContextClass): boolean {
    const backend_func = "api/TrackEvent";
    const key = "?code=ebolXAhNSyFInNiOythmiZIKk_Z1DIHZ3gbUQaHWeE4TAzFukLOquA=="

    const payload = {
        version: "1.0",
        app_name: app_name,
        language: paramLang,

        utc_offset: GetCurrentOffset(),
        timezone: GetCurrentTimezone(),

        event_category: paramEventCategory,
        event_name: paramEventName,
        event_details: paramEventDetails,
        anonym_guid: GetOrCreateAnonymGUID(appContext)
    };

    // Call asynchronously
    backend(backend_func, key, payload).catch((ex) => {
        hyDebugLocal("TrackEvent error: " + ex);
    }
    );

    return true;
}


