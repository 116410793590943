import * as React from 'react';
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';

interface SegmentProps {
    className?: string;
    fillFlex?: boolean;
    fillWidth?: boolean
    fillHeight?: boolean
    style?: React.CSSProperties;
    children: React.ReactNode;
}

// Assuming GriffelStyle supports CSS properties in this format.
const useStyles = makeStyles({
    segment: {
        paddingTop: tokens.spacingVerticalM,
        paddingBottom: tokens.spacingVerticalM,
        paddingLeft: tokens.spacingHorizontalM,
        paddingRight: tokens.spacingHorizontalM,

        backgroundColor: tokens.colorNeutralBackground1,

        borderRadiusTop: tokens.borderRadiusMedium,
        borderRadiusBottom: tokens.borderRadiusMedium,
        borderRadiusLeft: tokens.borderRadiusMedium,
        borderRadiusRight: tokens.borderRadiusMedium,

        boxShadow: tokens.shadow2,
    },

    segmentFillFlex: {
        flex: 1
    },
    segmentGrowWidth: {
        width: '100%'
    },
    segmentGrowHeight: {
        height: '100%'
    }


});

const Segment: React.FC<SegmentProps> = ({ className, fillFlex, fillWidth, fillHeight, style, children }) => {
    const styles = useStyles();

    return <div className={mergeClasses(styles.segment, className, (fillFlex ? styles.segmentFillFlex : undefined), (fillWidth ? styles.segmentGrowWidth : undefined), (fillHeight ? styles.segmentGrowHeight : undefined))} style={style}>{children}</div>;
};

export default Segment;
