import React from 'react';
import { makeStyles } from '@fluentui/react-components';

const useFlexStyles = makeStyles({
  flexCcontainer: {
    boxSizing: "border-box",
    display: "flex"
  },

  inlineFlexContainer: {
    boxSizing: "border-box",
    display: "inline-flex"
  }
});

// Awesome documentation: https://css-tricks.com/snippets/css/a-guide-to-flexbox/

/**
 * JustifyContent: Definiert die Ausrichtung von Items entlang der Hauptachse des Flex-Containers,
 * die horizontal (row) oder vertikal (column) sein kann, abhängig von der Flex-Richtung.
 * - 'flex-start': Gruppiert Items am Anfang der Hauptachse.
 * - 'flex-end': Gruppiert Items am Ende der Hauptachse.
 * - 'center': Zentriert Items auf der Hauptachse.
 * - 'space-between': Verteilt Items gleichmäßig; der erste Item ist am Anfang, der letzte am Ende.
 * - 'space-around': Verteilt Items mit gleichen Abständen rundherum.
 * - 'space-evenly': Gleichmäßiger Abstand zwischen allen Items und zum Anfang/Ende der Achse.
 *
 * AlignItems: Definiert die Ausrichtung von Items entlang der Querachse des Flex-Containers.
 * - 'flex-start': Ausrichtung der Items am Anfang der Querachse.
 * - 'flex-end': Ausrichtung der Items am Ende der Querachse.
 * - 'center': Zentriert Items auf der Querachse.
 * - 'stretch': Streckt Items, um den Container zu füllen, wenn keine Höhe/Breite festgelegt ist.
 * - 'baseline': Ausrichtung der Items an ihrer Text-Baseline.
 *
 * AlignContent: Steuert die Ausrichtung von Zeilen/Spalten innerhalb des Flex-Containers 
 * entlang der Querachse, wenn mehrere Flex-Zeilen oder -Spalten vorhanden sind.
 * - 'flex-start': Gruppiert Zeilen/Spalten am Anfang der Querachse.
 * - 'flex-end': Gruppiert Zeilen/Spalten am Ende der Querachse.
 * - 'center': Zentriert Zeilen/Spalten auf der Querachse.
 * - 'stretch': Streckt Zeilen/Spalten, um den Container vollständig zu füllen.
 * - 'space-between': Verteilt Zeilen/Spalten gleichmäßig; die erste ist am Anfang, die letzte am Ende.
 * - 'space-around': Verteilt Zeilen/Spalten gleichmäßig mit gleichen Abständen rundherum.
 */

type JustifyContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
type AlignItems = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
type AlignContent = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'space-between' | 'space-around';

const gapSizes = {
  none: '0px',
  smallest: '2px',
  smaller: '4px',
  small: '8px',
  medium: '16px',
  large: '32px',
  larger: '64px',
};
type GapSize = keyof typeof gapSizes; // 'none' | 'smaller' | 'small' | 'medium' | 'large' | 'larger'

const paddingSizes = {
  none: '0px',
  smallest: '2px',
  smaller: '4px',
  small: '8px',
  medium: '16px',
  large: '32px',
  larger: '64px',
};
type PaddingSize = keyof typeof paddingSizes; // 'none' | 'smaller' | 'small' | 'medium' | 'large' | 'larger'

interface FlexProps {
  column?: boolean;
  wrap?: boolean; // Aktualisiert, um ein einfaches boolean zu sein
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  wrapAlignContent?: AlignContent;
  gap?: GapSize; // Aktualisiert, um den neuen GapSize-Typ zu verwenden
  padding?: PaddingSize; // Neue padding Eigenschaft

  fillFlex?: boolean;
  fillWidth?: boolean;
  fillHeight?: boolean;

  inline?: boolean;
  debug?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export function Flex(props: FlexProps) {
  const classes = useFlexStyles();

  const gapStyle: React.CSSProperties = {
    gap: gapSizes[props.gap ?? 'none'],
  };

  const paddingStyle: React.CSSProperties = {
    padding: paddingSizes[props.padding ?? 'none'],
  };

  const directionStyle: React.CSSProperties = props.column ? { flexDirection: 'column' } : { /*Standard: flexDirection: 'row'*/ };
  const wrapstyle: React.CSSProperties = props.wrap ? { flexWrap: 'wrap' } : { /*Standard: flexWrap: 'nowrap' */ };

  const fillStyle: React.CSSProperties = props.fillFlex ? { flex: 1 } : {};
  const fillWidthStyle: React.CSSProperties = props.fillWidth ? { width: "100%" } : {};
  const fillHeightStyle: React.CSSProperties = props.fillHeight ? { height: "100%" } : {};

  const debugStyle: React.CSSProperties = props.debug ? { border: '2px solid red' } : {};

  return (
    <div
      className={props.inline ? classes.inlineFlexContainer : classes.flexCcontainer}
      style={{
        ...gapStyle,
        ...paddingStyle,
        ...directionStyle,
        ...wrapstyle,
        ...fillStyle,
        ...fillWidthStyle,
        ...fillHeightStyle,
        ...debugStyle,
        justifyContent: props.justifyContent,
        alignItems: props.alignItems,
        alignContent: props.wrapAlignContent,
        ...props.style,
      }}
    >
      {props?.children}
    </div>
  );
}

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

type AlignSelf = 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';

interface FlexItemProps {
  push?: boolean;
  column?: boolean; // Neues optional Prop, um die Fließrichtung anzugeben
  alignSelf?: AlignSelf; // Neuer Prop für align-self
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export function FlexItem(props: FlexItemProps) {
  // Berechne den Style basierend auf `push` und der Fließrichtung
  const pushStyle: React.CSSProperties = props.push
    ? props.column
      ? { marginTop: 'auto' } // Für 'column', schiebe mit marginTop
      : { marginLeft: 'auto' } // Für 'row', schiebe mit marginLeft
    : {};

  // Kombiniere benutzerdefinierte Styles mit dem Push-Style
  const itemStyle: React.CSSProperties = {
    ...pushStyle,
    alignSelf: props.alignSelf,
    ...props.style,
  };

  return (<div style={itemStyle}>{props?.children}</div>);
};

// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

type EmptyFlexSize = '0.25' | '0.5' | '1.0' | '1.5' | '2.0';

interface EmptyFlexRowProps {
  size?: EmptyFlexSize;
}

export function EmptyFlexRow(props: EmptyFlexRowProps) {
  const itemHeight = props.size ? (props.size + "em") : "1em";

  return (<FlexItem column style={{ height: itemHeight }} />)
}

export function EmptyFlexColumn(props: EmptyFlexRowProps) {
  const itemWidth = props.size ? (props.size + "em") : "1em";

  return (<FlexItem style={{ width: itemWidth }} />)
}