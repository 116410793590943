import { Dialog, Button, DialogSurface, DialogTitle, DialogContent, Text, DialogActions, Image, DialogBody, Checkbox, Input, MessageBar, MessageBarBody, Spinner, InputOnChangeData, MessageBarActions } from "@fluentui/react-components";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Dismiss24Regular, DismissRegular, KeyRegular, CartRegular } from "@fluentui/react-icons";

import { EmptyFlexRow, Flex, FlexItem } from "sub/blocks/FlexBoxes";
import { TabContext } from "sub/context/TabContext";
import { dialog_close_refresh, link_purchase, refreshTicker } from "sub/library/epCommon";
import { AppContext } from "sub/context/AppContext";
import { hyDebug } from "sub/library/hyDebug";
import { backendTrackEvent, backendUserManageLicense } from "sub/library/epBackend";
import { UserManageLicenseCode } from "sub/library/epBackendTypes";
import Alert from "sub/blocks/Alert";

enum LicenseWorkflow {
    None = "None",
    EnterLicense = "EnterLicense",
    PerformTransfer = "PerformTransfer",
    ManageLicense = "ManageLicense",
    Revoke = "Revoke",
}

export function TabDialogManageLicense() {
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    const [licenseWorkflow, setLicenseWorkflow] = useState(appContext.appAuthData.hasActiveLicense ? LicenseWorkflow.ManageLicense : LicenseWorkflow.EnterLicense);

    const [licenseKey, setLicenseKey] = useState("");
    const [warningText, setWarningText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [quantity, setQuantity] = useState(0);

    let l_provider = "p";

    useEffect(() => {
        // Refresh authorization data once
        appContext.setAuthRefreshTicker(refreshTicker);

        backendTrackEvent(appContext.appAuthData.lang, "Tab", "Dialog.Upgrade", licenseWorkflow, appContext);

    }, []);

    const licensePlaceholder = (licenseWorkflow === LicenseWorkflow.EnterLicense) ? "License key" : "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX";

    const bodyDialog = (<>
        <DialogTitle action={<Button appearance="subtle" aria-label="close" onClick={() => { dialog_close_refresh(tabContext); }} icon={<Dismiss24Regular />} />}>
            {licenseWorkflow === LicenseWorkflow.EnterLicense && ("Upgrade to Pro plan")}
            {licenseWorkflow === LicenseWorkflow.PerformTransfer && ("No more seats available")}
            {licenseWorkflow === LicenseWorkflow.ManageLicense && ("Manage your license")}
            {licenseWorkflow === LicenseWorkflow.Revoke && ("Revoke license")}
        </DialogTitle>

        <DialogContent>
            <Flex column gap="small">
                <EmptyFlexRow size="0.25" />
                {licenseWorkflow === LicenseWorkflow.EnterLicense && (<Text wrap>You can use Easy Poll for free for a limited number of polls per month and votes per poll. To create polls without restriction, please enter your license key here. For more information, please visit our <a href="https://www.easy-poll.app" target="_blank">website</a>.</Text>)}

                {licenseWorkflow === LicenseWorkflow.PerformTransfer && (<Text wrap>You have reached the maximum of your available seats (Quantity: {quantity}). Please <a href={link_purchase} target="_blank">purchase</a> new licenses.</Text>)}

                {licenseWorkflow === LicenseWorkflow.ManageLicense && (<Text wrap><b>Congratulations!</b> You have signed up for the Pro plan. Your polls will be created and shown without any restriction. For more information, please visit our <a href="https://www.easy-poll.app" target="_blank">website</a>.</Text>)}
                {licenseWorkflow === LicenseWorkflow.Revoke && (<Text wrap>Revoking the license frees it up for use by others on your team. If you need more licenses, you can also <a href={link_purchase} target="_blank">purchase</a> new licenses if necessary.</Text>)}

                <EmptyFlexRow size="1.0" />

                {(licenseWorkflow === LicenseWorkflow.EnterLicense || licenseWorkflow === LicenseWorkflow.ManageLicense) && (
                    <FlexItem column alignSelf="center">
                        <Input
                            style={{ minWidth: "25em" }}
                            placeholder={licensePlaceholder}
                            contentBefore={<KeyRegular />}
                            disabled={isLoading || licenseWorkflow === LicenseWorkflow.ManageLicense}
                            value={licenseKey}

                            onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { if (licenseWorkflow === LicenseWorkflow.EnterLicense) { setLicenseKey(data.value); } }}
                        />
                    </FlexItem>
                )}
                {(licenseWorkflow === LicenseWorkflow.PerformTransfer) && (
                    <FlexItem column alignSelf="center">
                        <Text wrap weight="bold">Please increase the number of licenses or purchase new licenses.</Text>
                    </FlexItem>
                )}

                {(licenseWorkflow === LicenseWorkflow.Revoke) && (
                    <FlexItem column alignSelf="center">
                        <Text wrap weight="bold">Do you really want to revoke your license?</Text>
                    </FlexItem>
                )}

                {warningText && (
                    <FlexItem column alignSelf="center">
                        <Alert wrap>
                            {warningText}
                        </Alert>
                    </FlexItem>
                )}

                <EmptyFlexRow size="1.0" />

                {licenseWorkflow === LicenseWorkflow.EnterLicense && (<Text wrap>Please note: Voting and participating in polls are always free and do not require a license. However, creating a poll without a license may restrict the visibility of the results once the limit is exceeded.</Text>)}

                {licenseWorkflow === LicenseWorkflow.PerformTransfer && (<Text wrap>Please note: You can transfer a license once every 3 months. When a license is transferred, one user from your team reverts back to the free plan with all restrictions.</Text>)}

                {licenseWorkflow === LicenseWorkflow.ManageLicense && l_provider === "m" && (<Text wrap>Did you know? You can increase the number of licenses or cancel your subscription any time on <a href="https://admin.microsoft.com/#/subscriptions" target="_blank">Microsoft 365 Portal</a>. You can also directly <a href={link_purchase} target="_blank">purchase</a> new licenses.</Text>)}
                {licenseWorkflow === LicenseWorkflow.Revoke && l_provider === "m" && (<Text wrap>Please note: Revoking will not cancel your subscription. For cancelling the subscription, please visit the <a href="https://admin.microsoft.com/#/subscriptions" target="_blank">Microsoft 365 Portal</a>.</Text>)}


                <EmptyFlexRow size="0.25" />
            </Flex>
        </DialogContent>
        <DialogActions fluid>
            {isLoading && (<Spinner />)}

            {licenseWorkflow === LicenseWorkflow.EnterLicense && (<>
                <Button disabled={isLoading} appearance="secondary" onClick={() => { _purchase_button(); }} icon={<CartRegular />}>Purchase license</Button>
                <Button disabled={isLoading} appearance="primary" onClick={() => { _activate(); }}>Activate</Button>
            </>)}

            {licenseWorkflow === LicenseWorkflow.PerformTransfer && (<>
                <Button disabled={isLoading} appearance="secondary" onClick={() => { setLicenseWorkflow(LicenseWorkflow.EnterLicense) }}>Back</Button>
                <Button disabled={isLoading} appearance="secondary" onClick={() => { _transfer(); }}>Request transfer</Button>
                <Button disabled={isLoading} appearance="primary" onClick={() => { _purchase_button(); }} icon={<CartRegular />}>Purchase license</Button>
            </>)}

            {licenseWorkflow === LicenseWorkflow.ManageLicense && (<>
                <Button disabled={isLoading} appearance="secondary" onClick={() => { setLicenseWorkflow(LicenseWorkflow.Revoke) }}>Revoke license</Button>
                <Button disabled={isLoading} appearance="primary" onClick={() => { dialog_close_refresh(tabContext); }}>Close</Button>
            </>)}

            {licenseWorkflow === LicenseWorkflow.Revoke && (<>
                <Button disabled={isLoading} appearance="secondary" onClick={() => { setLicenseWorkflow(LicenseWorkflow.ManageLicense) }}>Back</Button>
                <Button disabled={isLoading} appearance="secondary" onClick={() => { _purchase_button(); }} icon={<CartRegular />}>Purchase license</Button>
                <Button disabled={isLoading} appearance="primary" onClick={() => { _revoke(); }}>Revoke</Button>
            </>)}



        </DialogActions>
    </>);

    return (
        <Dialog defaultOpen onOpenChange={(event, data) => { if (!data.open) { dialog_close_refresh(tabContext); } }}>
            <DialogSurface>
                <DialogBody >
                    {bodyDialog}
                </DialogBody>
            </DialogSurface>
        </Dialog>

    );

    function _purchase_button() {
        backendTrackEvent(appContext.appAuthData.lang, "Tab", "Upgrade.Purchase", licenseWorkflow, appContext);
        window.open(link_purchase, '_blank');
    }

    async function _activate() {
        setIsLoading(true);
        setWarningText("");

        try {

            // Check for license key.
            if (!licenseKey) {
                setWarningText("Please enter a valid license key. You can purchase licenses on our website.");
                setIsLoading(false);
                return;
            }

            const result = await backendUserManageLicense("assign", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.lang, licenseKey);

            // Set license quantity for potential license transfer.
            setQuantity(result.license_quantity);

            if (result.success) {
                // Great!
                dialog_close_refresh(tabContext, appContext);
            } else {
                if (result.code === UserManageLicenseCode.LicenseNotFound || result.code === UserManageLicenseCode.LicenseWrongFormat) {
                    setWarningText("License key not found. Please enter a valid license key.");
                } else if (result.code === UserManageLicenseCode.LicenseNotEnoughSeats) {
                    setLicenseWorkflow(LicenseWorkflow.PerformTransfer);
                } else {
                    setWarningText("Something went wrong. Please try again or contact support.");
                }
            }
        } catch (ex: any) {
            setWarningText("Something went wrong. Please try again or contact support.");
            hyDebug("License not activated: " + ex.message);
        }

        setIsLoading(false);
    }


    async function _transfer() {
        setIsLoading(true);
        setWarningText("");

        try {

            const result = await backendUserManageLicense("transfer", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.lang, licenseKey);

            // Set license quantity for potential license transfer.
            setQuantity(result.license_quantity);

            if (result.success) {
                // Great!
                dialog_close_refresh(tabContext, appContext);
            } else if (result.code === UserManageLicenseCode.LicenseTransferDenied) {
                setWarningText(result.warning);
            } else {
                setWarningText("Something went wrong. Please try again or contact support.");
            }
        } catch (ex: any) {
            setWarningText("Something went wrong. Please try again or contact support.");
            hyDebug("License not activated: " + ex.message);
        }

        setIsLoading(false);
    }


    async function _revoke() {
        setIsLoading(true);
        setWarningText("");

        try {

            const result = await backendUserManageLicense("revoke", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.lang, "");

            if (result.success) {
                // Great!
                dialog_close_refresh(tabContext, appContext);
            } else {
                setWarningText("Something went wrong. Please try again or contact support.");
            }
        } catch (ex: any) {
            setWarningText("Something went wrong. Please try again or contact support.");
            hyDebug("License not activated: " + ex.message);
        }

        setIsLoading(false);
    }

}