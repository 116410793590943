import { DayOfWeek } from "@fluentui/react-calendar-compat";
import { PollDataClass } from "sub/bot-client-shared/PollDataClass";

export class GetUserHashResults {
    success: boolean = false;
    token: string = "";

    constructor(data?: any) {
        if (data) {
            this.success = data.success ?? this.success;
            this.token = data.token ?? this.token;
        }
    }
}

export class UpdateLoginResults {
    success: boolean = false;
    sso_required : boolean = false;
    has_active_license : boolean = false;
    firstDayOfWeek : number = DayOfWeek.Sunday;
    poll_limit : boolean = false;

    constructor(data?: any) {
        if (data) {
            this.success = data.success ?? this.success;
            this.sso_required = data.sso_required ?? this.sso_required;
            this.has_active_license = data.has_active_license ?? this.has_active_license;
            this.firstDayOfWeek = data.firstDayOfWeek ?? this.firstDayOfWeek;
            
            this.poll_limit = data.poll_limit ?? this.poll_limit;
        }
    }
}

export class ListPollResults {
    success: boolean = false;
    polls: PollDataClass[] = [];

    constructor(data?: any) {
        if (data) {
            this.success = data.success ?? this.success;
            this.polls = (data.polls ?? []).map((poll: any) => new PollDataClass(poll));
        }
    }
}

export enum CreateEditVoteWarnings {
    no_warning,
    poll_not_found,
    poll_is_closed,
    no_answer_selected,
    limit_violation
}

export class CreateEditVoteResults {
    success: boolean = false;
    code: CreateEditVoteWarnings = CreateEditVoteWarnings.no_warning;
    warning: string = "";
    pollData: PollDataClass = new PollDataClass();

    constructor(data?: any) {
        if (data) {
            this.success = data.success ?? this.success;
            this.code = data.code ?? this.code;
            this.warning = data.message ?? this.warning;

            if (data.pollData) {
                this.pollData = new PollDataClass(data.pollData);
            }
        }
    }
}


export class CreatePollResults {
    success: boolean = false;
    code: number = 0;
    warning: string = "";
    pollData: PollDataClass = new PollDataClass();

    constructor(data?: any) {
        if (data) {
            this.success = data.success ?? this.success;
            this.code = data.code ?? this.code;
            this.warning = data.message ?? this.warning;

            if (data.pollData) {
                this.pollData = new PollDataClass(data.pollData);
            }
        }
    }
}

export enum EditPollWarnings {
    no_warning,
    poll_not_found,
    user_is_not_creator,
    unlock_poll_already_used // This month
}

export class EditPollResults {
    success: boolean = false;
    code: EditPollWarnings = EditPollWarnings.no_warning;
    warning: string = "";
    pollData: PollDataClass = new PollDataClass();

    constructor(data?: any) {
        if (data) {
            this.success = data.success ?? this.success;
            this.code = data.code ?? this.code;
            this.warning = data.message ?? this.warning;

            if (data.pollData) {
                this.pollData = new PollDataClass(data.pollData);
            }
        }
    }
}

export class GetPollOrResultResults {
    success: boolean = false;
    code: number = 0;
    warning: string = "";
    pollData: PollDataClass = new PollDataClass();

    constructor(data?: any) {
        if (data) {
            this.success = data.success ?? this.success;
            this.code = data.code ?? this.code;
            this.warning = data.message ?? this.warning;

            if (data.pollData) {
                this.pollData = new PollDataClass(data.pollData);
            }
        }
    }
}


export enum UserManageLicenseCode {
    no_warning,
    LicenseWrongFormat,
    LicenseNotFound,
    LicenseNotEnoughSeats,
    LicenseTransferDenied
}

export class UserManageLicenseResults {
    success: boolean = false;
    code: UserManageLicenseCode = UserManageLicenseCode.no_warning;
    warning: string = "";
    
    license_quantity: number = 0;

    constructor(data?: any) {
        if (data) {
            this.success = data.success ?? this.success;
            this.code = data.code ?? this.code;
            this.warning = data.warning ?? this.warning;

            this.license_quantity = data.license_quantity ?? this.license_quantity;
        }
    }
}
