import { Dialog, Button, DialogSurface, DialogTitle, DialogContent, Text, DialogActions, Image, DialogBody, Spinner } from "@fluentui/react-components";
import { useContext, useState } from "react";
import { TabContext, TabDialogType } from "sub/context/TabContext";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { backendEditPollClient } from "sub/library/epBackend";
import { AppContext } from "sub/context/AppContext";
import { dialog_close_refresh, refreshTicker } from "sub/library/epCommon";
import { hyDebug } from "sub/library/hyDebug";
import { app } from "@microsoft/teams-js";

export function TabDialogDeletePoll() {
    const appContext = useContext(AppContext);
    const tabContext = useContext(TabContext);

    const [isWorking, setIsWorking] = useState(false);

    const subject = (tabContext.tabDialog === TabDialogType.DeletePoll) ? "poll" : "template";

    return (
        <Dialog defaultOpen onOpenChange={(event, data) => { if (!data.open) { dialog_close_refresh(tabContext); } }}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle action={<Button appearance="subtle" aria-label="close" onClick={() => { dialog_close_refresh(tabContext); }} icon={<Dismiss24Regular />} />}>Warning</DialogTitle>
                    <DialogContent>
                        <p>You are about to delete the {subject} <b>{tabContext.activePollElement.current.question}</b>.</p>
                        <p>Are you sure you want to delete this {subject}?</p>
                    </DialogContent>
                    <DialogActions>
                        {isWorking && (<Spinner />)}
                        <Button disabled={isWorking} appearance="secondary" onClick={() => { dialog_close_refresh(tabContext); }}>Close</Button>
                        <Button disabled={isWorking} appearance="primary" onClick={async () => { await _deleteActivePoll(); }}>Delete</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );


    async function _deleteActivePoll() {
        try {
            setIsWorking(true);

            // Call backend to change the poll status
            const responseTask = backendEditPollClient("delete", appContext.appAuthData.userUPN, appContext.appAuthData.userID, appContext.appAuthData.userToken, appContext.appAuthData.lang, tabContext.activePollElement.current.poll_guid, tabContext.activePollElement.current.poll_secret);

            // If the response was successful, update GUI immediately
            tabContext.listPollResults.current.polls = tabContext.listPollResults.current.polls.filter((poll) => poll.poll_guid !== tabContext.activePollElement.current.poll_guid);
            tabContext.setRefreshTickerGUI(refreshTicker);

            // Wait for the final response before refreshing the data from the backend.
            await responseTask;

            // Trigger a refresh of the data from the backend.
            tabContext.setRefreshTickerData(refreshTicker);

            // Close the dialog
            tabContext.setTabDialog(TabDialogType.None)
            setIsWorking(false);
        } catch (err: any) {
            hyDebug(err?.message);
            setIsWorking(false);
        }
    }
}