import { TabTableMode } from "sub/context/TabContext";
import { GenerateGUID } from "./epCommon";

export class SettingsStorage {
    public hideGetStartedOnStartup: boolean = false;
    public anonym_guid: string = "";

    public constructor(data?: any) {
        this.hideGetStartedOnStartup = data?.hideGetStartedOnStartup ?? this.hideGetStartedOnStartup;
        this.anonym_guid = data?.anonym_guid ?? this.anonym_guid;
    }

    public static WriteToStorage(data: SettingsStorage): void {
        localStorage.setItem("ep_settings", JSON.stringify(data));
    }

    public static CreateFromStorage(): SettingsStorage {
        const data = localStorage.getItem("ep_settings");
        if (data) {
            return new SettingsStorage(JSON.parse(data));
        } else {
            return new SettingsStorage({});
        }
    }
}

export class SettingsSession {
    public viewResultWithDetails : boolean = false;
}

export class ViewSettings {
    public storage: SettingsStorage = new SettingsStorage();
    public session: SettingsSession = new SettingsSession();

    // Create a new instance of the ViewSettings class
    // with the settings read from the local storage.
    public static CreateFromStorage(): ViewSettings {
        const ret = new ViewSettings();
        ret.storage = SettingsStorage.CreateFromStorage();
        
        return ret;
    }
}