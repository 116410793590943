// Chat GPT generated.

import * as React from 'react';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { InfoLabel, Input, InputOnChangeData, Label, Radio, Switch, Text, tokens } from '@fluentui/react-components';
import { ChevronDownRegular, ChevronRightRegular } from '@fluentui/react-icons';
import { EmptyFlexRow, Flex } from '../blocks/FlexBoxes';
import Segment from 'sub/blocks/Segment';
import { PollContext } from 'sub/context/PollContext';
import { hyDebug } from 'sub/library/hyDebug';
import { AppContext } from 'sub/context/AppContext';
import { link_purchase } from 'sub/library/epCommon';
import { PopupFreePlanLimit } from './CommonMisc';


export function PollOptions() {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const appContext = useContext(AppContext);
    const pollContext = useContext(PollContext);

    const [showBuyPopup, setShowBuyPopup] = useState(false);
    const refButtonPost = useRef<HTMLInputElement>(null);


    return (
        <Flex column gap="smaller">
            <Flex gap="smaller" alignItems="center" wrap>
                {isOpen ? (<ChevronDownRegular onClick={toggleOpen} style={{ cursor: "pointer" }} />) : (<ChevronRightRegular onClick={toggleOpen} style={{ cursor: "pointer" }} />)}
                <Text onClick={toggleOpen} style={{ cursor: "pointer" }} weight='bold'>Options: </Text>

                {!isOpen &&
                    (<Text style={{ color: tokens.colorNeutralForeground2 }} wrap>
                        {!pollContext.pollData.option_votes_have_limit ? "Multiple answers per vote" : ("Max. " + pollContext.pollData.option_votes_max_limit + (pollContext.pollData.option_votes_max_limit === 1 ? " answer per vote" : " answers per vote"))}
                        {pollContext.pollData.option_votes_are_anonymous ? " | Anonymous voting" : " | Non-anonymous voting"}
                        {pollContext.pollData.option_results_creator_only ? " | Results for creator only" : " | Results for all voters"}
                    </Text>)
                }
            </Flex>

            {isOpen && (
                <Segment fillWidth>
                    <Flex column wrap justifyContent='space-between'>

                        <Flex alignItems='center'>
                            <Text weight='semibold'><InfoLabel label={"Answers per vote"} info={"Specifies the maximum number of answers a user can select per vote. If you select multiple answers, the user can select any up to all answers."} />:</Text>

                            <Radio checked={pollContext.pollData.option_votes_have_limit === true} onClick={() => { __setOptionVotesHaveLimit(true); }}
                                label={
                                    <Flex gap="small" alignItems='center' wrap>
                                        <Label onClick={() => { __setOptionVotesHaveLimit(true); }} style={{ cursor: "pointer" }}>Max:</Label>
                                        <Input
                                            onClick={() => { __setOptionVotesHaveLimit(true); }} type="number" min={1}
                                            onChange={(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => { __onOptionAnswerMaxLimitChange(ev, data); }} size="small" style={{ width: "5em" }} value={pollContext.pollData.option_votes_max_limit.toString()}
                                        />
                                    </Flex>
                                }

                                style={{ alignItems: 'center' }}
                            />

                            <Radio checked={pollContext.pollData.option_votes_have_limit === false} onClick={() => { __setOptionVotesHaveLimit(false); }} label="Multiple answers" />

                        </Flex>

                        <Flex alignItems='center'>
                            <Text weight='semibold'>Names:</Text>
                            <Switch label="Votes are anonymous" checked={pollContext.pollData.option_votes_are_anonymous} onChange={(event) => { __setOptionVotesAreAnonymous(event.target.checked); }} />
                        </Flex>

                        <Text weight='bold' style={{ color: tokens.colorNeutralForeground2BrandHover }} ><InfoLabel label={"Pro"} info={<Text>You need a Pro license to use these options. <a href="https://www.easy-poll.app/buy" target="_blank">Learn more</a>.</Text>} /></Text>

                        {showBuyPopup && (
                            <PopupFreePlanLimit appContext={appContext} situation="pro_option" refElement={refButtonPost} fShowPopup={setShowBuyPopup} />
                        )}


                        <Flex alignItems='center'>
                            <Text weight='semibold'><InfoLabel label="Results" info="By default, everyone can see the results of a poll after voting. With this option activated, only the creator can see the results." />:</Text>
                            <Switch ref={refButtonPost} label="Visible only for creator" checked={pollContext.pollData.option_results_creator_only} onChange={(event) => { __setOptionResultsForCreatorOnly(event.target.checked); }} />
                        </Flex>


                    </Flex>
                </Segment>
            )}
            <EmptyFlexRow />
        </Flex>
    );

    function __setOptionVotesHaveLimit(val: boolean) {
        pollContext.updatePollData({ option_votes_have_limit: val });
    }

    function __onOptionAnswerMaxLimitChange(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        try {
            pollContext.updatePollData({ option_votes_max_limit: Number(data.value) });
        } catch (ex) {
            hyDebug("Error setting limit: " + ex);
            pollContext.updatePollData({ option_votes_max_limit: 1 });
        }
    }

    function __setOptionExpiresAuto(val: boolean) {
        pollContext.updatePollData({ option_expires_auto: val });
    }

    function __setOptionExpirationDateTime(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        try {
            pollContext.updatePollData({ option_expires_datetime: data.value });
        } catch (ex) {
            hyDebug("Error setting expiration datetime: " + ex);
        }
    }

    function __setOptionVotesAreAnonymous(val: boolean) {
        pollContext.updatePollData({ option_votes_are_anonymous: val });
    }

    function __setOptionResultsForCreatorOnly(val: boolean) {
        if (appContext.appAuthData.hasActiveLicense) {
            pollContext.updatePollData({ option_results_creator_only: val });
        } else {
            // Open webpage to buy license via browser
            pollContext.updatePollData({ option_results_creator_only: false });
            setShowBuyPopup(true);

        }
    }


};
