const environment = (window.location.hostname === "localhost" ? "local" : "azure");

export function hyDebug(text: string, onlyLocal: boolean = false) {

    if (onlyLocal) {
        if (environment === "local") {
            // Debug only on local environment
            console.log("hyOffice: " + text + " (#DEBUG)");
        }
    } else {
        // Always debug
        console.log("hyOffice: " + text);
    }
}

export function hyDebugLocal(text: string) {
    hyDebug(text, true);
}